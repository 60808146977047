var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyCklfCKvV3xsZHsoCBPyPr9FtU6MJGW-NY';


(function() {
    if ($('#maps').length) {
        gmaps.load(function(google) {

            var map;

            function initialize() {
                var glat = 51.1623598;
                var glong = 2.7622012;

                var mapOptions = {
                    zoom: 14,
                    scrollwheel: false,
                    center: new google.maps.LatLng(glat, glong),
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_CENTER
                    },
                };
                map = new google.maps.Map(document.getElementById('maps'), mapOptions);

                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(glat, glong),
                    map: map
                });
            }
            google.maps.event.addDomListener(window, 'load', initialize);
        });
    }
})();
