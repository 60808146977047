$("#mobile-nav").mmenu({
        "offCanvas": {
            position: "top",
            pageSelector: ".fullcontainer"
        },
        navbar: false,
        slidingSubmenus: false,
        extensions: ["fullscreen","border-none"]
    });

var API = $("#mobile-nav").data("mmenu");

$(".btn-close").click(function () {
    API.close();
});
