require("./plugins/bootstrap.js");
require("./plugins/magnific-popup.js");
require("./plugins/slick.js");
require("./plugins/bootstrap-datepicker.js");
require("./plugins/jquery.mmenu.min.all.js");

require("./modules/forms.js");
require("./modules/reviews.js");
require("./modules/fastbooker.js");
require("./modules/mobile-nav.js");
require("./modules/sliders.js");
require("./modules/images.js");
require("./modules/cbpicker");

if ($("#maps").length) {
  require("./modules/location.js");
}

var el = document.querySelector(".js-cbpicker");

// CBPicker Config
var config = {
  el: el,
  startDateEl: document.querySelector("#startDate"),
  startDateLabel: document.querySelector("#startDateLabel"),
  endDateEl: document.querySelector("#endDate"),
  endDateLabel: document.querySelector("#endDateLabel"),
  firstDayOfWeek: 1,
  orientation: "horizontal"
};

if (el) {
  var cbpicker = new CBPicker(config);
}

var btn = $(".js-fastbooker-btn");
btn.click(function(e) {
  e.preventDefault();
  var arrival = new Date($("#startDate").val());
  var departure = new Date($("#endDate").val());
  var arrivalString =
    arrival.getFullYear() +
    "/" +
    ("0" + (arrival.getMonth() + 1)).slice(-2) +
    "/" +
    arrival.getDate();
  var departureString =
    departure.getFullYear() +
    "/" +
    ("0" + (departure.getMonth() + 1)).slice(-2) +
    "/" +
    departure.getDate();
  var href =
    "https://reservations.cubilis.eu/belcasa-family-suites-brugge?Language=" +
    getCubilisLang();

  if (
    arrival !== "undefined-undefined-" &&
    departure !== "undefined-undefined-"
  ) {
    if ($(".js-promocode").length && $(".js-promocode").val() !== "") {
      href =
        "https://reservations.cubilis.eu/belcasa-family-suites-brugge/Rooms/Select?Language=" +
        getCubilisLang() +
        "&Arrival=" +
        arrivalString +
        "&Departure=" +
        departureString +
        "&DiscountCode=" +
        $(".js-promocode").val();
    } else {
      href =
        "https://reservations.cubilis.eu/belcasa-family-suites-brugge/Rooms/Select?Language=" +
        getCubilisLang() +
        "&Arrival=" +
        arrivalString +
        "&Departure=" +
        departureString;
    }
  }
  window.open(href, "_blank");
});

function getCubilisLang() {
  var lang = $("html").attr("lang");
  var cubilisLang = "en-GB";
  if (lang === "nl") {
    cubilisLang = "nl-NL";
  } else if (lang === "fr") {
    cubilisLang = "fr-FR";
  } else if (lang === "de") {
    cubilisLang = "de-DE";
  }
  return cubilisLang;
}
