$(".main-slider").slick({
  arrows: true,
  fade: true
});

$(".review-slider").slick({
  slide: ".review"
});

$(".js-room-overview").slick({
  prevArrow: $(".js-slick-prev"),
  nextArrow: $(".js-slick-next"),
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
});

function isMobile() {
  if (window.innerWidth <= 992) return true;
}

var slider = $(".js-room-overview");
var slideCount = isMobile()
  ? $(".js-room-block:not(.slick-cloned)").length
  : Math.ceil($(".js-room-block:not(.slick-cloned)").length / 2);
var slideTotal = $(".js-room-nav-total");
var counter = $(".js-room-nav-counter");
var curSlide = slider.slick("slickCurrentSlide");
var next = $(".js-slick-next");

counter.text(curSlide + 1);
slideTotal.text(slideCount);

slider.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
  if (isMobile()) {
    counter.text(nextSlide + 1);
  } else {
    counter.text(Math.ceil((nextSlide + 1) / 2));
  }
});

next.on("click", function(e) {
  e.preventDefault();
  slider.slick("slickNext");
});

window.addEventListener("resize", function() {
  if (isMobile()) {
    slideCount = $(".js-room-block:not(.slick-cloned)").length;
  } else {
    slideCount = Math.ceil($(".js-room-block:not(.slick-cloned)").length / 2);
  }
  slideTotal.text(slideCount);
});
